<template>
  <v-card :to="{name: 'branch', params: {branch_id: branch.id, branch}}" tile>
    <v-img :aspect-ratio="16/9" :src="branch.site.cover ? branch.site.cover : require('@/assets/images/no-image.png')" class="white--text align-end" height="100%">
      <v-row align="center" class="action-bar px-3 py-0" no-gutters>
        <v-col>
          <v-card-title class="pa-0" v-text="`${branch.site.name} #${branch.number}`"/>
        </v-col>

        <v-col cols="12">
          <v-card-text class="text-start pa-0">
            <v-card-subtitle class="px-0 py-1 white--text">
              <v-icon color="white">mdi-phone-in-talk-outline</v-icon>
              {{ branch.manager_phone_number }}
            </v-card-subtitle>
            <v-card-subtitle class="px-0 py-1 white--text">
              <v-icon color="white">mdi-email-outline</v-icon>
              {{ branch.site.email }}
            </v-card-subtitle>
            <v-card-subtitle class="px-0 py-1 white--text">
              <v-icon color="white">mdi-google-maps</v-icon>
              {{ branch.full_address }}
            </v-card-subtitle>
          </v-card-text>
        </v-col>
      </v-row>
    </v-img>
  </v-card>
</template>

<script>
import Branch from '@/modules/app/models/branch'

export default {
  name: 'KurccBranchCard',
  props: {
    branch: {
      type: Branch,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.action-bar {
  background: rgba(0, 0, 0, 0.5);
}
</style>
